import Close from 'apollo-react-icons/Close';
import Upload from 'apollo-react-icons/Upload';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import CardContent from 'apollo-react/components/CardContent';
import CardHeader from 'apollo-react/components/CardHeader';
import Container from 'apollo-react/components/Container';
import Divider from 'apollo-react/components/Divider';
import FileUpload from 'apollo-react/components/FileUpload';
import Grid from 'apollo-react/components/Grid';
import TextField from 'apollo-react/components/TextField';
import Typography from 'apollo-react/components/Typography';
import { unionWith } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmDialog from '../common/ConfirmDialog';
import { API_URL, OBJ_ROLE, SYS_ROLE } from '../common/Constants';
import DetailDialog from '../common/DetailDialog';
import Dialog from '../common/Dialog';
import Loading from '../common/Loading';
import { checkFileName, getFuncroleFlg, postApi, setMessage, setURL, redirectToLogin } from '../common/Utils';
import './UploadPage.scss';

/**
 * アップロード
 */
function UploadPage() {
	const location = useLocation();
	const [t, i18n] = useTranslation();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const DIALOG_STATUS = {
		NO_DIALOG: '0',
		OK_DIALOG: '1',
		DETAIL_DIALOG: '2',
		UPLOAD_CONFIRM_DIALOG: '3',
		UPLOAD_COMPLETE_DIALOG: '4',
	};
	const [dialogStatus, setDialogStatus] = useState(DIALOG_STATUS.NO_DIALOG);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogContent, setDialogContent] = useState('');
	const [detailContent, setDetailContent] = useState('');
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const [confirmDialogContent, setConfirmDialogContent] = useState('');

	// ファイル選択の権限フラグ
	const [uploadFileSelectorAuthFlg, setUploadFileSelectorAuthFlg] = useState(false);
	// お知らせの権限フラグ
	const [informationAuthFlg, setInformationAuthFlg] = useState(false);
	// 特記事項の権限フラグ
	const [noticesAuthFlg, setNoticesAuthFlg] = useState(false);
	// アップロードボタンの権限フラグ
	const [uploadFileBtnAuthFlg, setUploadFileBtnAuthFlg] = useState(false);
	// 画面に表示されている情報
	const [formValues, setFormValues] = useState({
		orgCode: '',
		orgName: '',
		serviceCode: '',
		serviceName: '',
		projCode: '',
		projName: '',
		selectedFiles: [],
		notices: '',
		information: ''
	});
	// エラーのファイル名のリスト
	const [errFileNameMessage, setErrFileNameMessage] = useState('');

	/**
	 * アップロードAPIの成功時の処理
	 */
	function uploadSuccess() {
		setDialogStatus(DIALOG_STATUS.UPLOAD_COMPLETE_DIALOG);
		const message = t('message.1000600');
		setDialogOpen(true);
		setDialogContent(message);
	};

	/**
	 * アップロードAPIの失敗時の処理
	 * @param {object} error エラーオブジェクト
	 */
	function uploadError(error) {
		if (error.name === 'MaintenanceApiError') {
			navigate('/', { state: { maintenanceFlg: true, }});
		} else if (error.name === 'CustomApiError') {
			//入力テキストに禁則文字が含まれている場合、azureのインフラルールによりエラーとなる
			//ただし、エラーは403 Forbiddenとしか返って来ない（＝禁則文字によるエラーだと判別できない）ため、メッセージを変更する
			const forbidCharPat1 = /.+403 Forbidden.+/;
			const forbidCharPat2 = /.+Microsoft-Azure-Application-Gateway.+/;
			if ((error.message.match(forbidCharPat1)) && (error.message.match(forbidCharPat2))) {
				const message = t('message.1000810');
				setDialogStatus(DIALOG_STATUS.OK_DIALOG);
				setDialogOpen(true);
				setDialogContent(message);

				//認証期限切れの場合は再ログイン
				redirectToLogin();
			} else {
				const message = error.message;
				setDialogStatus(DIALOG_STATUS.OK_DIALOG);
				setDialogOpen(true);
				setDialogContent(message);

				//認証期限切れの場合は再ログイン
				redirectToLogin();
			}
		} else {
			const message = 'アップロード処理でエラーが発生しました。\n(An error occurred during the upload process.)';
			setDialogStatus(DIALOG_STATUS.DETAIL_DIALOG);
			setDialogOpen(true);
			setDialogContent(message);
			setDetailContent(error.stack);

			//認証期限切れの場合は再ログイン
			redirectToLogin();
		}

		//認証期限切れの場合は再ログイン
		redirectToLogin();
	};

	/**
	 * ファイル選択時の処理
	 * @param {object} files 選択したファイルの情報
	 */
	const handleUploadFile = (files) => {
		const selectedFiles = unionWith(
			formValues.selectedFiles,
			files,
			(item1, item2) => {
				return item1.name === item2.name;
			},
		);
		const errFileNameList = selectedFiles.filter(selectedFile => checkFileName(selectedFile.name) === false).map(errFile => errFile.name);
		if (errFileNameList.length > 0) {
			setErrFileNameMessage(errFileNameList.join(', '));
		} else {
			setErrFileNameMessage('');
		}
		setFormValues({ ...formValues, selectedFiles });
	}

	/**
	 * ファイル削除時の処理
	 * @param {object} file 削除したファイルの情報
	 */
	const handleDeleteFile = (file) => {
		const selectedFiles = formValues.selectedFiles.filter((item) => item.name !== file.name);
		const errFileNameList = selectedFiles.filter(selectedFile => checkFileName(selectedFile.name) === false).map(errFile => errFile.name);
		if (errFileNameList.length > 0) {
			setErrFileNameMessage(errFileNameList.join(', '));
		} else {
			setErrFileNameMessage('');
		}
		setFormValues({ ...formValues, selectedFiles });
	}

	/**
	 * アップロードボタン押下時の処理
	 */
	const handleUploadClick = () => {
		const message = t('message.1000500');
		setDialogStatus(DIALOG_STATUS.UPLOAD_CONFIRM_DIALOG);
		setConfirmDialogOpen(true);
		setConfirmDialogContent(message)
	}

	/**
	 * ダイアログでOKボタン押下時の処理
	 */
	const onOk = () => {
		setDialogStatus(DIALOG_STATUS.NO_DIALOG);
		setDialogOpen(false);
	};

	/**
	 * アップロード時の確認ダイアログでOKボタン押下時の処理
	 */
	const onUploadConfirmOk = async () => {
		setConfirmDialogOpen(false);
		// アップロードAPI
		const url = setURL(API_URL.UPLOAD_CLIENT);
		let formData = new FormData();
		formData.append('orgLevel0Code', formValues.orgCode);
		formData.append('auditCode', formValues.serviceCode);
		formData.append('projCode', formValues.projCode);
		formData.append('notices', formValues.notices);
		formData.append('information', formValues.information);
		for (let selectedFile of formValues.selectedFiles){
			formData.append('file', selectedFile);
		}
		const postApiParam = {
			url: url,
			sysRole: SYS_ROLE.UPLOAD_PAGE,
			body: formData,
			success: uploadSuccess,
			error: uploadError,
		};
		postApi(postApiParam);
	};

	/**
	 * 確認ダイアログでキャンセルボタン押下時の処理
	 */
	const onCancel = () => {
		setDialogStatus(DIALOG_STATUS.NO_DIALOG);
		setConfirmDialogOpen(false);
	};

	/**
	 * 共通ファイル管理遷移処理
	 */
	const commonBack = () => {
		const param = {
			org: formValues.orgCode,
			service: formValues.serviceCode,
			proj: formValues.projCode,
		};
		// ファイル管理画面へ遷移
		navigate('/filemanagement', { state: param });
	};

	/**
	 * アップロード完了ダイアログでOKボタン押下時の処理
	 */
	const onCompleteOk = () => {
		setDialogStatus(DIALOG_STATUS.NO_DIALOG);
		setDialogOpen(false);
		commonBack();
	};

	/**
	 * キャンセルボタン押下時の処理
	 */
	const handleCancelClick = () => {
		commonBack();
	}
	useEffect(() => {
		(async () => {
			if (location.state === null) {
				// ファイル管理画面へ遷移
				navigate('/filemanagement');
				return;
			}
			// ファイル選択の権限フラグを設定
			setUploadFileSelectorAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_PAGE, OBJ_ROLE.UPLOAD_FILE_SELECTOR));
			// 特記事項の権限フラグを設定
			setNoticesAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_PAGE, OBJ_ROLE.NOTICES));
			// お知らせの権限フラグを設定
			setInformationAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_PAGE, OBJ_ROLE.INFORMATION));
			// アップロードボタンの権限フラグを設定
			setUploadFileBtnAuthFlg(getFuncroleFlg(SYS_ROLE.UPLOAD_PAGE, OBJ_ROLE.UPLOAD_FILE_BTN));
			setFormValues({ ...formValues, ...location.state });
			setIsLoading(false);
		})();
	}, []);

	if (isLoading) {
		// ロード中の場合はローディング画面
		return (
			<Loading />
		);
	} else {
		return (
			<Container className="uploadPage" maxWidth="md">
				<div className="row justify-content-end">
				</div>
				<Card className="card">
					<CardHeader
						title={t('upload')}
						subheader={t('uploadScreenDescription')}
					/>
					<CardContent>
						<Grid container>
							<Grid item md={12} className="griditem">
								<TextField
									label={'1. ' + t('selectOrg')}
									value={formValues.orgName}
									disabled
								/>
							</Grid>
							<Grid item md={12} className="griditem">
								<TextField
									label={'2. ' + t('selectService')}
									value={formValues.serviceName}
									disabled
								/>
							</Grid>
							<Grid item md={12} className="griditem">
								<TextField
									label={'3. ' + t('selectProj')}
									value={formValues.projName}
									disabled
								/>
							</Grid>
							<Grid item md={12} className="griditem uploadFileSelector">
								<FileUpload
									label={'4. ' + t('uploadFileSelect')}
									required
									value={formValues.selectedFiles}
									onUpload={handleUploadFile}
									onFileDelete={handleDeleteFile}
									dropAreaLabels={{
										activeText: 'アップロードするファイルをドロップしてください。',
										browseLinkText: t('dropAreaBrowseLinkText'),
										hoverText: t('dropAreaHoverText'),
										maxText: '選択可能最大数'
									}}
									disabled={!uploadFileSelectorAuthFlg}
									fullWidth
								/>
								{formValues.selectedFiles.length === 0 && (
									<Typography className='fileErrorMessage' variant='caption'>
										{setMessage(t('message.1000200'), [t('file')])}
									</Typography>
								)}
								{errFileNameMessage.length !== 0 && (
									<Typography className='fileErrorMessage' variant='caption'>
										{setMessage(t('message.1000800'), [errFileNameMessage])}
									</Typography>
								)}
							</Grid>
						</Grid>
						<Grid item md={12} className="griditem notices">
							<TextField
								label={'5. ' + t('notice')}
								helperText={formValues.notices.length > 300 ? setMessage(t('message.1000300'), [t('notice').toLowerCase(), 300]) : t('helperTextNotice')}
								value={formValues.notices}
								onChange={(e) => setFormValues({ ...formValues, notices: e.target.value })}
								error={formValues.notices.length > 300}
								multiline
								minRows="2"
								optional
								fullWidth
								disabled={!noticesAuthFlg}
							/>
						</Grid>
						<Grid item md={12} className="griditem information">
							<TextField
								label={'6. ' + t('information')}
								helperText={formValues.information.length > 300 ? setMessage(t('message.1000300'), [t('information').toLowerCase(), 300]) : t('helperTextInformation')}
								value={formValues.information}
								onChange={(e) => setFormValues({ ...formValues, information: e.target.value })}
								error={formValues.information.length > 300}
								multiline
								minRows="2"
								optional
								fullWidth
								disabled={!informationAuthFlg}
							/>
						</Grid>
						<Divider className="divider" type="dark" />
						<Button
							className="uploadFileBtn"
							variant="primary"
							icon={Upload}
							disabled={formValues.selectedFiles.length === 0 || errFileNameMessage.length !== 0 || formValues.notices.length > 300 || formValues.information.length > 300}
							onClick={handleUploadClick}
							style={{ display: uploadFileBtnAuthFlg ? "inline-flex" : "none"}}
						>
							{t('upload')}
						</Button>
						<Button
							className="cancelBtn"
							variant="secondary"
							icon={Close}
							onClick={handleCancelClick}
						>
							{t('cancel')}
						</Button>
						{dialogStatus === DIALOG_STATUS.OK_DIALOG && (
							<Dialog
								isOpen={dialogOpen}
								content={dialogContent}
								onOk={onOk}
							/>
						)}
						{dialogStatus === DIALOG_STATUS.DETAIL_DIALOG && (
							<DetailDialog
								isOpen={dialogOpen}
								content={dialogContent}
								detailContent={detailContent}
								onOk={onOk}
							/>
						)}
						{dialogStatus === DIALOG_STATUS.UPLOAD_CONFIRM_DIALOG && (
							<ConfirmDialog
								isOpen={confirmDialogOpen}
								content={confirmDialogContent}
								onOk={onUploadConfirmOk}
								onCancel={onCancel}
							/>
						)}
						{dialogStatus === DIALOG_STATUS.UPLOAD_COMPLETE_DIALOG && (
							<Dialog
								isOpen={dialogOpen}
								content={dialogContent}
								onOk={onCompleteOk}
							/>
						)}
					</CardContent>
				</Card>
			</Container>
		);
	}
}

export default UploadPage;
