import { Link } from 'apollo-react';
import Download from 'apollo-react-icons/Download';
import Plus from 'apollo-react-icons/Plus';
import Trash from 'apollo-react-icons/Trash';
import Button from 'apollo-react/components/Button';
import DateRangePicker from 'apollo-react/components/DateRangePicker';
import Grid from 'apollo-react/components/Grid';
import IconButton from 'apollo-react/components/IconButton';
import Select from 'apollo-react/components/Select';
import Table, { compareStrings } from 'apollo-react/components/Table';
import Tooltip from 'apollo-react/components/Tooltip';
import { format } from 'date-fns';
import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmDialog from '../common/ConfirmDialog';
import { API_URL, OBJ_ROLE, STATUS_CODE, SYS_ROLE } from '../common/Constants';
import DetailDialog from '../common/DetailDialog';
import Dialog from '../common/Dialog';
import Loading from '../common/Loading';
import { deleteApi, getApi, getFuncroleFlg, setMessage, setURL, getHeader, MaintenanceApiError, CustomApiError, redirectToLogin } from '../common/Utils';
import downloadData from '../json/downloadData.json';
import fileManagementData from '../json/fileManagementData.json';
import selectItemAuditData from '../json/selectItemAuditData.json';
import selectItemOrgData from '../json/selectItemOrgData.json';
import selectItemProjData from '../json/selectItemProjData.json';
import './FileManagement.scss';

/**
 * ファイル管理
 */
function FileManagement() {
	const location = useLocation();
	const [t, i18n] = useTranslation();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const DIALOG_STATUS = {
		NO_DIALOG: '0',
		OK_DIALOG: '1',
		DETAIL_DIALOG: '2',
		DELETE_CONFIRM_DIALOG: '3',
	};
	const [dialogStatus, setDialogStatus] = useState(DIALOG_STATUS.NO_DIALOG);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogContent, setDialogContent] = useState('');
	const [detailContent, setDetailContent] = useState('');
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const [confirmDialogContent, setConfirmDialogContent] = useState('');

	// 企業名選択の権限フラグ
	const [orgSelectorAuthFlg, setOrgSelectorAuthFlg] = useState(false);
	// サービス名選択の権限フラグ
	const [serviceSelectorAuthFlg, setServiceSelectorAuthFlg] = useState(false);
	// プロジェクト名選択の権限フラグ
	const [projSelectorAuthFlg, setProjSelectorAuthFlg] = useState(false);
	// アップロード日選択の権限フラグ
	const [deliveryDateRangeAuthFlg, setDeliveryDateRangeAuthFlg] = useState(false);
	// アップロードボタンの権限フラグ
	const [uploadBtnAuthFlg, setUploadBtnAuthFlg] = useState(false);
	// ファイルダウンロードリンクの権限フラグ
	const [downloadFileLinkAuthFlg, setDownloadFileLinkAuthFlg] = useState(false);
	// 削除ボタンの権限フラグ
	const [deleteFileBtnAuthFlg, setDeleteFileBtnAuthFlg] = useState(false);
	// ダウンロードユーザー名表示権限フラグ
	const [downloadUserViewFlg, setDownloadUserViewFlg] = useState(false);
	// 企業名の選択リスト
	const [orgList, setOrgList] = useState([]);
	// サービス名の選択リスト
	const [serviceList, setServiceList] = useState([]);
	// プロジェクト名の選択リスト
	const [projList, setProjList] = useState([]);
	// アップロードボタンの権限リスト
	const [uploadBtnAuthList, setUploadBtnAuthList] = useState([]);
	// ファイルダウンロードリンクの権限リスト
	const [downloadFileLinkAuthList, setDownloadFileLinkAuthList] = useState([]);
	// 削除ボタンの権限リスト
	const [deleteFileBtnAuthList, setDeleteFileBtnAuthList] = useState([]);
	// 一覧の表示リスト
	const [fileManagementList, setFileManagementList] = useState([]);
	// 検索条件で選択している情報
	const [formValues, setFormValues] = useState({
		org: '',
		service: '',
		proj: '',
		startDate: null,
		endDate: null,
		startDateErrFlg: false,
		endDateErrFlg: false,
	});
	// 選択しているデータ
	const [selectedData, setSelectedData] = useState({
		projRateNo: '',
		fileId: '',
	});

	const FileNameCell = ({ row }) => {
		const fileNameCellDisabledCondition = !downloadFileLinkAuthFlg || (row.projStatus !== STATUS_CODE.STORAGE_COMPLETE && row.projStatus !== STATUS_CODE.DELIVERY_STORAGE_COMPLETE);
		return (
			<Link className="downloadFileLink" disabled={fileNameCellDisabledCondition} onClick={() => handleDownloadClick(row)}>
				<Download fontSize="small" />{row.fileName}
			</Link>
		);
	};

	const DateCell = ({ row }) => {
		const ret = row.entryDate !== null ? format(new Date(row.entryDate), 'yyyy/MM/dd HH:mm') : '';
		return ret;
	};

	const DeleteCell = ({ row }) => {
		const deleteCellDisplayCondition = deleteFileBtnAuthFlg && (row.projStatus === STATUS_CODE.STORAGE_COMPLETE || row.projStatus === STATUS_CODE.DELIVERY_STORAGE_COMPLETE);
		return (
			<div>
				<Tooltip title={t('delete')} disableFocusListener placement="right">
					<span>
						<IconButton
							className="deleteFileBtn"
							size="small"
							onClick={() => handleDeleteClick(row)}
							style={{ display: deleteCellDisplayCondition ? "block" : "none" }}
						>
							<Trash/>
						</IconButton>
					</span>
				</Tooltip>
			</div>
		);
	}

	const columns = [
		{
			header: t('fileName'),
			accessor: 'fileName',
			customCell: FileNameCell,
			sortFunction: compareStrings,
			width: 250,
		},
		{
			header: t('uploadDate'),
			accessor: 'entryDate',
			customCell: DateCell,
			fixedWidth: true,
			sortFunction: compareStrings,
			width: 160,
		},
		{
			header: t('uploadUser'),
			accessor: 'upUsr',
			sortFunction: compareStrings,
			width: 180,
		},
		{
			header: t('downloadUser'),
			accessor: 'downUsr',
			sortFunction: compareStrings,
			width: 360,
			overflow: 'hidden',
			textOverflow: 'ellipsis', 
		},
		{
			header: t('notice'),
			accessor: 'notices',
			sortFunction: compareStrings,
			width: 250,
		},
		{
			header: t('status'),
			accessor: 'statusName',
			sortFunction: compareStrings,
			width: 180,
		},
		{
			header: '',
			accessor: 'delete',
			customCell: DeleteCell,
			width: 40,
		},
	];

	// ユーザー区分を取得
	const usr_type_code = JSON.parse(localStorage.getItem("usrTypeCode"));
	const filteredColumns = columns.filter(column => {
		if (usr_type_code.usrTypeCode === "2" && column.accessor === 'downUsr') {
			return false;
		}
		return true;
	});

	/**
	 * 企業選択項目取得APIの成功時の処理
	 * @param {array} response 企業選択項目取得APIのレスポンス
	 */
	function selectOrgApiSuccess(response) {
		if (location.state !== null && location.state.org !== '') {
			if (response.find(org => org.orgLevel0Code === location.state.org) !== undefined) {
				commonGetService(location.state.org);
			}
		} else if (response.length === 1) {
			// 企業名の選択肢が1つのみの場合、サービス選択項目取得処理実行
			commonGetService(response[0].orgLevel0Code);
		}

		// 企業名選択リストをセット
		setOrgList(response);
	};

	/**
	 * サービス選択項目取得APIの成功時の処理
	 * @param {array} response サービス選択項目取得APIのレスポンス
	 * @param {string} orgCode 企業コード
	 */
	function selectServiceApiSuccess(response, orgCode) {
		if (location.state !== null && location.state.service !== '') {
			if (response.find(audit => audit.auditCode === location.state.service) !== undefined) {
				commonGetProj(location.state.org, location.state.service);
			} else {
				setFormValues({ ...formValues, ...{org: location.state.org, service: '', proj: ''} });
				location.state = null;
			}
		} else if (response.length === 1) {
			// サービス名の選択肢が1つのみの場合、プロジェクト選択項目取得処理実行
			commonGetProj(orgCode, response[0].auditCode);
		} else {
			setFormValues({ ...formValues, ...{org: orgCode, service: '', proj: ''} });
			location.state = null;
		}

		// サービス名選択リストをセット
		setServiceList(response);
	};

	/**
	 * プロジェクト選択項目取得APIの成功時の処理
	 * @param {object} response プロジェクト選択項目取得APIのレスポンス
	 * @param {object} option 選択している企業コード、サービスコードが設定されているオブジェクト
	 */
	function selectProjApiSuccess(response, option) {
		let updObj = {}
		if (location.state !== null && location.state.proj !== '') {
			if (response.proj.find(proj => proj.projCode === location.state.proj) !== undefined) {
				updObj = location.state;
			} else {
				updObj = {org: location.state.org, service: location.state.service, proj: ''}
			}
		} else if (response.proj.length === 1) {
			updObj = {org: option.orgCode, service: option.auditCode, proj: response.proj[0].projCode}
		} else {
			updObj = {org: option.orgCode, service: option.auditCode, proj: ''}
		}
		setFormValues({ ...formValues, ...updObj });
		location.state = null;

		// formValuesはレンダリング後に値が反映されるため、ディープコピーして別の変数に値をセットする
		let currentObj = structuredClone(formValues);
		currentObj = { ...currentObj, ...updObj };
		search(currentObj, {uploadBtnAuthList: response.uploadBtn, downloadFileLinkAuthList: response.downloadFileLink, deleteFileBtnAuthList: response.deleteFileBtn});

		// プロジェクト名選択リストをセット
		setProjList(response.proj);
		// アップロードボタンの権限リストをセット
		setUploadBtnAuthList(response.uploadBtn);
		// ファイルダウンロードリンクの権限リストをセット
		setDownloadFileLinkAuthList(response.downloadFileLink);
		// 削除ボタンの権限リストをセット
		setDeleteFileBtnAuthList(response.deleteFileBtn);
	};

	/**
	 * ダウンロードリスト取得APIの成功時の処理
	 * @param {object} response ダウンロードリスト取得APIのレスポンス
	 */
	function searchApiSuccess(response) {
		setFileManagementList(response);
	};

	/**
	 * ダウンロードAPIの成功時の処理
	 * @param {object} response ダウンロードAPIのレスポンス
	 */
	async function downloadApiSuccess(response) {
		try {
			const fileName = response.fileDownloadName;
			const options = { 'suggestedName': fileName };
			const handle = await window.showSaveFilePicker(options);
			const writable = await handle.createWritable();
			const blob = new Blob([Uint8Array.from(atob(response.fileContents), str => str.charCodeAt(0))]);
			await writable.write(blob);
			await writable.close();
		} catch (error) {
			const detailErrorMessage = 'ダウンロード処理で処理でエラーが発生しました。\n(An error occurred during the select item process.)';
			commonError(error, detailErrorMessage);
		}
	};

	/**
	 * アップロード済ファイル削除APIの成功時の処理
	 */
	function deleteApiSuccess() {
		// 検索処理
		search(formValues);

		setDialogStatus(DIALOG_STATUS.OK_DIALOG);
		const message = t('message.1001100');
		setDialogOpen(true);
		setDialogContent(message);
	};

	/**
	 * 選択項目取得処理の失敗時の処理
	 * @param {object} error エラーオブジェクト
	 */
	function selectItemApiError(error) {
		const detailErrorMessage = '選択項目取得処理でエラーが発生しました。\n(An error occurred during the select item process.)';
		commonError(error, detailErrorMessage);

		//認証期限切れの場合は再ログイン
		redirectToLogin();
	};

	/**
	 * ダウンロードリスト取得APIの失敗時の処理
	 * @param {object} error エラーオブジェクト
	 */
	function searchApiError(error) {
		const detailErrorMessage = 'ダウンロードリスト取得処理でエラーが発生しました。\n(An error occurred during the download list acquisition process.)';
		commonError(error, detailErrorMessage);

		//認証期限切れの場合は再ログイン
		redirectToLogin();
	};

	/**
	 * ダウンロードAPIの失敗時の処理
	 * @param {object} error エラーオブジェクト
	 */
	function downloadApiError(error) {
		const detailErrorMessage = 'ダウンロード処理でエラーが発生しました。\n(An error occurred during the download process.)';
		commonError(error, detailErrorMessage);

		//認証期限切れの場合は再ログイン
		redirectToLogin();
	};

	/**
	 * アップロード済ファイル削除APIの失敗時の処理
	 * @param {object} error エラーオブジェクト
	 */
	function deleteApiError(error) {
		const detailErrorMessage = 'アップロード済ファイル削除処理でエラーが発生しました。\n(An error occurred while deleting the uploaded files.)';
		commonError(error, detailErrorMessage);

		//認証期限切れの場合は再ログイン
		redirectToLogin();
	};

	/**
	 * 共通エラー処理
	 * @param {object} error エラーオブジェクト
	 * @param {string} detailErrorMessage 詳細ダイアログに設定するエラーメッセージ
	 */
	const commonError = (error, detailErrorMessage) => {
		if (error.name === 'MaintenanceApiError') {
			navigate('/', { state: { maintenanceFlg: true, }});
		} else if (error.name === 'CustomApiError') {
			const message = error.message;
			setDialogStatus(DIALOG_STATUS.OK_DIALOG);
			setDialogOpen(true);
			setDialogContent(message);
		} else {
			setDialogStatus(DIALOG_STATUS.DETAIL_DIALOG);
			setDialogOpen(true);
			setDialogContent(detailErrorMessage);
			setDetailContent(error.stack);
		}
	};

	/**
	 * クリア処理
	 */
	const clear = () => {
		setUploadBtnAuthFlg(false);
		setDownloadFileLinkAuthFlg(false);
		setDeleteFileBtnAuthFlg(false);
		setUploadBtnAuthList([]);
		setDownloadFileLinkAuthList([]);
		setDeleteFileBtnAuthList([]);
		setFileManagementList([]);
	}

	/**
	 * 企業名の選択ダイアログで選択時の処理
	 * @param {object} event イベント
	 */
	const selectOrg = (event) => {
		clear();
		setServiceList([]);
		setProjList([]);
		if (event.target.value === '') {
			const updObj = {org: '', service: '', proj: ''};
			setFormValues({ ...formValues, ...updObj });
		} else {
			commonGetService(event.target.value);
		}
	};

	/**
	 * 共通サービス選択項目取得処理
	 * @param {string} orgCode 企業コード
	 */
	const commonGetService = async (orgCode) => {
		// サービス選択項目取得API
		const url = setURL(API_URL.SELECT_ITEM_AUDIT) + '?';
		// クエリパラメータ作成
		const params = {};
		params['orgLevel0Code'] = orgCode;
		const urlSearchParam =  String(new URLSearchParams(params));
		const getApiParam = {
			url: url + urlSearchParam,
			sysRole: SYS_ROLE.FILE_MANAGEMENT,
			success: selectServiceApiSuccess,
			error: selectItemApiError,
			json: selectItemAuditData,
			option: orgCode
		}
		await getApi(getApiParam);
	};

	/**
	 * サービス名の選択ダイアログで選択時の処理
	 * @param {object} event イベント
	 */
	const selectService = (event) => {
		clear();
		setProjList([]);
		if (event.target.value === '') {
			const updObj = {service: '', proj: ''};
			setFormValues({ ...formValues, ...updObj });
		} else {
			commonGetProj(formValues.org, event.target.value);
		}
	};

	/**
	 * 共通プロジェクト選択項目取得処理
	 * @param {string} orgCode 企業コード
	 * @param {string} auditCode サービスコード
	 */
	const commonGetProj = async (orgCode, auditCode) => {
		// プロジェクト選択項目取得API
		const url = setURL(API_URL.SELECT_ITEM_PROJ) + '?';
		// クエリパラメータ作成
		const params = {};
		params['orgLevel0Code'] = orgCode;
		params['auditCode'] = auditCode;
		const urlSearchParam =  String(new URLSearchParams(params));
		const getApiParam = {
			url: url + urlSearchParam,
			sysRole: SYS_ROLE.FILE_MANAGEMENT,
			success: selectProjApiSuccess,
			error: selectItemApiError,
			json: selectItemProjData,
			option: {orgCode: orgCode, auditCode: auditCode}
		}
		await getApi(getApiParam);
	};


	/**
	 * 選択ダイアログで選択時の処理
	 * @param {object} event イベント
	 */
	const selectProj = (event) => {
		setFileManagementList([]);
		// formValuesはレンダリング後に値が反映されるため、ディープコピーして別の変数に値をセットする
		let currentObj = structuredClone(formValues);

		const updObj = {proj: event.target.value}
		setFormValues({ ...formValues, ...updObj });
		currentObj = { ...currentObj, ...updObj };
		search(currentObj);
	};

	/**
	 * アップロード日の選択時の処理
	 * @param {object} event イベント
	 */
	const selectDate = (event) => {
		// formValuesはレンダリング後に値が反映されるため、ディープコピーして別の変数に値をセットする
		let currentObj = structuredClone(formValues);

		let updObj = {};
		if (event[0] !== null) {
			updObj.startDate = event[0]._d;
		} else {
			updObj.startDate = null;
		}
		if (event[1] !== null) {
			updObj.endDate = event[1]._d;
		} else {
			updObj.endDate = null;
		}
		updObj.startDateErrFlg = updObj.startDate === null ? false : isNaN(updObj.startDate.getDate());
		updObj.endDateErrFlg = updObj.endDate === null ? false : isNaN(updObj.endDate.getDate());
		setFormValues({ ...formValues, ...updObj });
		currentObj = { ...currentObj, ...updObj };
		search(currentObj);
	};

	/**
	 * 検索処理
	 * @param {object} currentObj 検索条件で選択している情報
	 * @param {object} authObj 権限のオブジェクト
	 */
	const search = async (currentObj, authObj = {uploadBtnAuthList: uploadBtnAuthList, downloadFileLinkAuthList: downloadFileLinkAuthList, deleteFileBtnAuthList: deleteFileBtnAuthList}) => {
		if (currentObj.service && currentObj.org && currentObj.proj) {
			// アップロードボタンの権限フラグを設定
			setUploadBtnAuthFlg(authObj.uploadBtnAuthList.find(uploadBtnAuth => uploadBtnAuth.orgLevel0Code === currentObj.org && uploadBtnAuth.auditCode === currentObj.service && uploadBtnAuth.projCode === currentObj.proj) !== undefined);
		}
		if (currentObj.service && currentObj.org && currentObj.proj && !currentObj.startDateErrFlg && !currentObj.endDateErrFlg) {
			// ファイルダウンロードリンクの権限フラグを設定
			setDownloadFileLinkAuthFlg(authObj.downloadFileLinkAuthList.find(downloadFileLinkAuth => downloadFileLinkAuth.orgLevel0Code === currentObj.org && downloadFileLinkAuth.auditCode === currentObj.service && downloadFileLinkAuth.projCode === currentObj.proj) !== undefined);
			// 削除ボタンの権限フラグを設定
			setDeleteFileBtnAuthFlg(authObj.deleteFileBtnAuthList.find(deleteFileBtnAuth => deleteFileBtnAuth.orgLevel0Code === currentObj.org && deleteFileBtnAuth.auditCode === currentObj.service && deleteFileBtnAuth.projCode === currentObj.proj) !== undefined);

			// ダウンロードリスト取得API
			const url = setURL(API_URL.UPHIST_CLIENT) + '?';

			// クエリパラメータ作成
			const params = {};
			params['orgLevel0Code'] = currentObj.org;
			params['auditCode'] = currentObj.service;
			params['projCode'] = currentObj.proj;
			const option = {year: 'numeric', month: '2-digit', day: '2-digit'};
			if (currentObj.startDate) {
				params['fromDate'] = currentObj.startDate.toLocaleDateString('ja-JP', option).split("/").join("-");
			};
			if (currentObj.endDate) {
				params['toDate'] = currentObj.endDate.toLocaleDateString('ja-JP', option).split("/").join("-");
			};
			const urlSearchParam =  String(new URLSearchParams(params));

			const getApiParam = {
				url: url + urlSearchParam,
				sysRole: SYS_ROLE.FILE_MANAGEMENT,
				success: searchApiSuccess,
				error: searchApiError,
				json: fileManagementData
			}
			await getApi(getApiParam);
		} else {
			setFileManagementList([]);
		}
	};

	/**
	 * アップロードボタン押下時の処理
	 */
	const handleUploadClick = () => {
		// 企業名の取得
		const orgName = orgList.find(orgLevel0 => orgLevel0.orgLevel0Code === formValues.org)['orgAbbr'];
		// サービス名の取得
		const serviceName = serviceList.find(audit => audit.auditCode === formValues.service)['auditName'];
		// プロジェクト名の取得
		const projName = projList.find(proj => proj.projCode === formValues.proj)['projName'];
		const param = {
			orgCode: formValues.org,
			orgName: orgName,
			serviceCode: formValues.service,
			serviceName: serviceName,
			projCode: formValues.proj,
			projName: projName,
		};
		// アップロード画面へ遷移
		navigate('/upload', { state: param });
	};

	/**
	 * ファイルのダウンロード時の処理
	 * @param {object} row 選択している行の情報
	 */
	const handleDownloadClick = async (row) => {
		try {
			//ファイル保存ダイアログを開きダウンロード先指定
			const fileName = row.fileName;
			const options = { 'suggestedName': fileName };
			const handle = await window.showSaveFilePicker(options);
			// ファイル保存ダイアログで入力したファイル名を取得
			const enteredFileName = await handle.name;
			console.log(`[IQVIA FRONT] [WebApp] [INFO] entered file name: ${enteredFileName}`);
			// ファイル保存ダイアログで指定したディレクトリ情報（親フォルダのハンドルがある場合のみ）を取得
			const parentDirectory = handle.kind === "file" && handle.isDirectory ? handle.directory : null;
			console.log(`[IQVIA FRONT] [WebApp] [INFO] parent directory: ${parentDirectory}`);
			// 書き込み用のストリームを開く
			const writableStream = await handle.createWritable();
			// ダウンロードAPI
			const url = setURL(API_URL.DOWNLOAD) + '/' + row.fileId + '?';
			// クエリパラメータ作成
			const params = {};
			params['auditCode'] = formValues.service;
			params['projCode'] = formValues.proj;
			params['projRateNo'] = row.projRateNo;
			const urlSearchParam =  String(new URLSearchParams(params));
			//ダウンロードAPI実行中は操作できないようにする
			document.getElementById('block').style.display = 'block';
			let blob;
			if (process.env.NODE_ENV === 'production') {
				const response = await fetch(url + urlSearchParam, getHeader(SYS_ROLE.FILE_MANAGEMENT));
				if (!response.ok) {
					if (response.status === 503) {
						localStorage.removeItem("funcroleList");
						throw new MaintenanceApiError();
					}
					throw new CustomApiError(await response.text());
				}

				// サーバーからファイルをダウンロードしBlobとして取得
				blob = await response.blob();
			} else {
				//ローカルデバッグ時ダウンロードするファイル
				blob = new Blob(['test,test'], {type: 'text/csv'})
			}
			// Blobデータを書き込む
			await writableStream.write(blob);
			// 書き込み完了後にストリームを閉じる
			await writableStream.close();
		} catch (error) {
			if (error instanceof DOMException) {
				//ファイル保存ダイアログを閉じただけなので何もしない
			} else {
				const detailErrorMessage = 'ダウンロード処理で処理でエラーが発生しました。\n(An error occurred during the select item process.)';
				console.log(`[IQVIA FRONT] [WebApp] [ERROR] ダウンロード処理で処理でエラーが発生: ${error.message}`);
				commonError(error, detailErrorMessage);
			}
		} finally {
			document.getElementById('block').style.display = 'none';
		}
	}

	/**
	 * 削除ボタン押下時の処理
	 * @param {object} row 選択している行の情報
	 */
	const handleDeleteClick = (row) => {
		const selectedObj = { projRateNo: row.projRateNo, fileId: row.fileId };
		setSelectedData({ ...selectedData, ...selectedObj });
		const message = setMessage(t('message.1001000'), [row.fileName]);
		setDialogStatus(DIALOG_STATUS.DELETE_CONFIRM_DIALOG);
		setConfirmDialogOpen(true);
		setConfirmDialogContent(message);
	};

	/**
	 * ダイアログでOKボタン押下時の処理
	 */
	const onOk = () => {
		setDialogStatus(DIALOG_STATUS.NO_DIALOG);
		setDialogOpen(false);
	};

	/**
	 * 削除時の確認ダイアログでOKボタン押下時の処理
	 */
	const onDeleteConfirmOk = async () => {
		setConfirmDialogOpen(false);
		// アップロード済ファイル削除API
		const url = setURL(API_URL.UPFILE);
		let formData = new FormData();
		formData.append('auditCode', formValues.service);
		formData.append('projCode', formValues.proj);
		formData.append('projRateNo', selectedData.projRateNo);
		formData.append('fileid', selectedData.fileId);
		const deleteApiParam = {
			url: url,
			sysRole: SYS_ROLE.FILE_MANAGEMENT,
			body: formData,
			success: deleteApiSuccess,
			error: deleteApiError,
		};
		await deleteApi(deleteApiParam);
	};

	/**
	 * 確認ダイアログでキャンセルボタン押下時の処理
	 */
	const onCancel = () => {
		setDialogStatus(DIALOG_STATUS.NO_DIALOG);
		setConfirmDialogOpen(false);
	};

	useEffect(() => {
		(async () => {
			if (window.localStorage.getItem(['token']) === null) {
				navigate('/')
				return;
			}
			const search = location.search;
			if (search !== '') {
				const query = new URLSearchParams(search);
				const orgValue = query.has('orgLevel0Code') ? query.get('orgLevel0Code') : '';
				const serviceValue = orgValue !== '' && query.has('auditCode') ? query.get('auditCode') : '';
				const projValue = orgValue !== '' && serviceValue !== '' && query.has('projCode') ? query.get('projCode') : '';
				location.state = {
					org: orgValue,
					service: serviceValue,
					proj: projValue,
				}
			}
			// 企業名選択の権限フラグを設定
			const orgSelectorAuthFlg = getFuncroleFlg(SYS_ROLE.FILE_MANAGEMENT, OBJ_ROLE.ORG_SELECTOR);
			setOrgSelectorAuthFlg(orgSelectorAuthFlg);
			// サービス名選択の権限フラグを設定
			const serviceSelectorAuthFlg = getFuncroleFlg(SYS_ROLE.FILE_MANAGEMENT, OBJ_ROLE.SERVICE_SELECTOR);
			setServiceSelectorAuthFlg(serviceSelectorAuthFlg);
			// プロジェクト名選択の権限フラグを設定
			const projSelectorAuthFlg = getFuncroleFlg(SYS_ROLE.FILE_MANAGEMENT, OBJ_ROLE.PROJ_SELECTOR);
			setProjSelectorAuthFlg(projSelectorAuthFlg);
			// アップロード日選択の権限フラグを設定
			setDeliveryDateRangeAuthFlg(getFuncroleFlg(SYS_ROLE.FILE_MANAGEMENT, OBJ_ROLE.DELIVERY_DATE_RANGE));

			if (orgSelectorAuthFlg && serviceSelectorAuthFlg && projSelectorAuthFlg) {
				// 企業選択項目取得API
				const url = setURL(API_URL.SELECT_ITEM_ORGLEVEL0);
				const getApiParam = {
					url: url,
					sysRole: SYS_ROLE.FILE_MANAGEMENT,
					success: selectOrgApiSuccess,
					error: selectItemApiError,
					json: selectItemOrgData
				}
				await getApi(getApiParam);
			}
			setIsLoading(false);
		})();
	}, []);

	if (isLoading) {
		// ロード中の場合はローディング画面
		return (
			<Loading />
		);
	} else {
		return (
			<div className="fileManagement">
				<Grid container spacing={2} className="filter">
					{/* <Grid item md={2} className="griditem"> */}
					<Grid item md={2} className="griditem">
						<Select
							name="org"
							label={t('org')}
							helperText={!formValues.org ? setMessage(t('message.1000200'), [t('org').toLowerCase()]) : ""}
							value={formValues.org}
							onChange={selectOrg}
							required
							error={!formValues.org}
							disabled={!orgSelectorAuthFlg}
							fullWidth
							className="orgSelector"
						>
							{orgList.map((org, index) => {
								return <option key={index} value={org.orgLevel0Code}>{org.orgAbbr}</option>;
							})}
						</Select>
					</Grid>
					<Grid item md={2} className="griditem">
						<Select
							name="service"
							label={t('service')}
							helperText={!formValues.service ? setMessage(t('message.1000200'), [t('service').toLowerCase()]) : ""}
							value={formValues.service}
							onChange={selectService}
							required
							error={!formValues.service}
							disabled={!serviceSelectorAuthFlg || !formValues.org}
							fullWidth
							className="serviceSelector"
						>
							{serviceList.map((service, index) => {
								return <option key={index} value={service.auditCode}>{service.auditName}</option>;
							})}
						</Select>
					</Grid>
					<Grid item md={3} className="griditem">
						<Select
							name="proj"
							label={t('proj')}
							helperText={!formValues.proj ? setMessage(t('message.1000200'), [t('proj').toLowerCase()]) : ""}
							value={formValues.proj}
							onChange={selectProj}
							required
							error={!formValues.proj}
							disabled={!projSelectorAuthFlg || !formValues.service}
							fullWidth
							className="projSelector"
						>
							{projList.map((proj, index) => {
								return <option key={index} value={proj.projCode}>{proj.projName}</option>;
							})}
						</Select>
					</Grid>
					<Grid item md={4} className="griditem">
						<DateRangePicker
							startLabel={t('uploadDate')}
							className="deliveryDateRange"
							endLabel="　"
							startProps={{ placeholder: 'From', error: formValues.startDateErrFlg }}
							endProps={{ placeholder: 'To', error: formValues.endDateErrFlg, helperText: formValues.endDateErrFlg ? t('message.1000400') : "" }}
							dateFormat='yyyy/MM/DD'
							helperText={formValues.startDateErrFlg ? t('message.1000400') : ""}
							onChange={selectDate}
							disabled={!deliveryDateRangeAuthFlg}
						/>
					</Grid>
				</Grid>
				<div className="buttons">
					<Button
						variant="primary"
						size="small"
						className="uploadBtn"
						status="success"
						onClick={handleUploadClick}
						disabled={!formValues.org || !formValues.service || !formValues.proj || !uploadBtnAuthFlg}
					>
						<Plus />{t('upload')}
					</Button>
				</div>
				<div className='table-wrap'>
				<Table
					className="downloadList"
					columns={filteredColumns}
					rowId="id"
					rows={fileManagementList}
					rowsPerPageOptions={[10, 50, 100, 'All']}
					rowProps={{ hover: false }}
					tablePaginationProps={{
						labelDisplayedRows: ({ from, to, count }) => `全${count}件`,
						truncate: true,
					}}
					emptyProps={{ text: t('message.1000100') }}
				/>
				</div>
				{dialogStatus === DIALOG_STATUS.OK_DIALOG && (
					<Dialog
						isOpen={dialogOpen}
						content={dialogContent}
						onOk={onOk}
					/>
				)}
				{dialogStatus === DIALOG_STATUS.DETAIL_DIALOG && (
					<DetailDialog
						isOpen={dialogOpen}
						content={dialogContent}
						detailContent={detailContent}
						onOk={onOk}
					/>
				)}
				{dialogStatus === DIALOG_STATUS.DELETE_CONFIRM_DIALOG && (
					<ConfirmDialog
						isOpen={confirmDialogOpen}
						content={confirmDialogContent}
						onOk={onDeleteConfirmOk}
						onCancel={onCancel}
					/>
				)}
			</div >
		);
	}
}

export default FileManagement;
