import { API_URL } from '../common/Constants';

/**
 * メッセージを返却する関数
 *
 * @param {string} message メッセージ
 * @param {array} array メッセージ変数リスト
 * @return メッセージ
 */
export function setMessage(message , array = []) {
    for (let variable of array) {
        message = message.replace(/{.}/, variable);
    }
    return message;
};

/**
 * URLを返却する関数
 *
 * @param {string} urlSuffix URLの末尾
 * @return URL
 */
export function setURL(urlSuffix) {
    if (process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_API_URL + urlSuffix;
    } else {
        return window.WebSiteURL + urlSuffix;
    }
};

/**
 * 権限ロールの有無を返却する関数
 *
 * @param {string} sysRole システムロールコード
 * @param {string} objRole オブジェクトロールコード
 * @return 権限チェック結果(OK: true、NG: false)
 */
export function getFuncroleFlg(sysRole, objRole) {
    return localStorage.getItem('funcroleList') !== null && JSON.parse(localStorage.getItem('funcroleList')).find(funcrole => funcrole.sysRole === sysRole && funcrole.objRole === objRole);
};

/**
 * タイムゾーン情報付きのISO 8601拡張形式で出力する関数
 *
 * @param {object} date 日付
 * @return フォーマット変換された日付(yyyy-MM-ddTHH:mm:ss)
 */
export function toISOStringWithTimezone(date) {
    const pad = function (str) {
        return ('0' + str).slice(-2);
    };
    const year = (date.getFullYear()).toString();
    const month = pad((date.getMonth() + 1).toString());
    const day = pad(date.getDate().toString());
    const hour = pad(date.getHours().toString());
    const min = pad(date.getMinutes().toString());
    const sec = pad(date.getSeconds().toString());
    const tz = -date.getTimezoneOffset();
    const sign = tz >= 0 ? '+' : '-';
    const tzHour = pad((tz / 60).toString());
    const tzMin = pad((tz % 60).toString());

    return `${year}-${month}-${day}T${hour}:${min}:${sec}${sign}${tzHour}:${tzMin}`;
}

export class MaintenanceApiError extends Error {
    name = 'MaintenanceApiError'
}

export class CustomApiError extends Error {
    name = 'CustomApiError'
}

/**
 * GETのAPIを呼び出す関数
 *
 * @param {object} param
 */
export async function getApi(param) {
    try {
        document.getElementById('block').style.display = 'block';
        let responseJson;
        if (process.env.NODE_ENV === 'production') {
            const response = await fetch(param.url, getHeader(param.sysRole));
            if (!response.ok) {
                if (response.status === 503) {
                    localStorage.removeItem("funcroleList");
                    throw new MaintenanceApiError();
                }
                if (response.status === 401) {
                    //再ログインが必要のためログイン画面を表示ためのフラグセット
                    localStorage.setItem('reAuthFlag', '1');
                }
                throw new CustomApiError(await response.text());
            }
            responseJson = await response.json();
        } else {
            responseJson = param.json;
        }
        if ('option' in param) {
            param.success(responseJson, param.option);
        } else {
            param.success(responseJson);
        }
    } catch (error) {
        param.error(error);
    } finally {
        document.getElementById('block').style.display = 'none';
    }
};

/**
 * POSTのAPIを呼び出す関数
 *
 * @param {object} param
 */
export async function postApi(param) {
    document.getElementById('block').style.display = 'block';
    try {
        let response;
        if (process.env.NODE_ENV === 'production') {
            let params = getHeader(param.sysRole);
            params.method = 'POST';
            params.body = param.body;
            response = await fetch(param.url, params);
            if (!response.ok) {
                if (response.status === 503) {
                    localStorage.removeItem("funcroleList");
                    throw new MaintenanceApiError();
                }
                if (response.status === 401) {
                    //再ログインが必要のためログイン画面を表示ためのフラグセット
                    localStorage.setItem('reAuthFlag', '1');
                }
                throw new CustomApiError(await response.text());
            }
        }
        param.success();
    } catch (error) {
        param.error(error);
    } finally {
        document.getElementById('block').style.display = 'none';
    }
};

/**
 * DELETEのAPIを呼び出す関数
 *
 * @param {object} param
 */
export async function deleteApi(param) {
    document.getElementById('block').style.display = 'block';
    try {
        let response;
        if (process.env.NODE_ENV === 'production') {
            let params = getHeader(param.sysRole);
            params.method = 'DELETE';
            params.body = param.body;
            response = await fetch(param.url, params);
            if (!response.ok) {
                if (response.status === 503) {
                    localStorage.removeItem("funcroleList");
                    throw new MaintenanceApiError();
                }
                if (response.status === 401) {
                    //再ログインが必要のためログイン画面を表示ためのフラグセット
                    localStorage.setItem('reAuthFlag', '1');
                }
                throw new CustomApiError(await response.text());
            }
        }
        param.success();
    } catch (error) {
        param.error(error);
    } finally {
        document.getElementById('block').style.display = 'none';
    }
};

/**
 * ファイル名のチェック結果を返却する関数
 *
 * @param {string} fileName ファイル名
 * @return チェック結果(OK: true、NG: false)
 */
export function checkFileName(fileName) {
    const reg = new RegExp(/[\\＼/／:：*＊"”<＜≦>＞≧|｜]/g);
    return !reg.test(fileName)
};

/**
 * 認証トークンがセットされたヘッダを返却する関数
 *
 * @param {string} sysRole 機能名
 * @return 認証トークン
 */
export function getHeader(sysRole) {
    return { headers: { 'Sysrole': sysRole, mode: 'cors'}};
};

/**
	 * 再認証が必要の場合はログイン画面に遷移
	 */
export function redirectToLogin() {
    if(window.localStorage.getItem(['reAuthFlag']) === '1') {
        localStorage.removeItem("reAuthFlag");
        console.log('redirect to ' + API_URL.LOGIN_INDEX);
        window.location.href = API_URL.LOGIN_INDEX;
    }
};